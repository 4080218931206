<template>
  <div class="home">
    <div class="top">
      <headers></headers>
    </div>

    <div class="bottom">
      <div class="left">
        <layout  :isCollapse='iscollapse' ></layout>
      </div>
      <div class="right">
        <div class="nav">
          <headerNav @collapse='collapse' ></headerNav>
        </div>
        <div class="main">
          <mains></mains>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import headers from '../components/headers/index.vue'
  import layout from '../components/layout/index.vue'
  import headerNav from '../components/headerNav/index.vue'
  import mains from './main'
  export default {
    components: {
      headers,
      layout,
      headerNav,
      mains
    },
    data() {
      return {
        iscollapse:false,
      }
    },
    methods: {
      collapse(val){
        this.iscollapse = val
      }
    },
    mounted() {
    },
  }
</script>

<style scoped>
  .home {
    height: 100%;
    width: 100%;
  }

  .top {
    width: 100%;
    height: 80px;
    background: #304156;
  }

  .bottom {
    position: absolute;
    left: 0;
    top: 80px;
    bottom: 0;
    right: 0;
    width: 100%;
    /* height: 100%; */
    overflow: hidden;
  }

  .bottom::after {
    content: "";
    display: block;
    clear: both;
    /* height: 0;overflow: hidden; 为了解决 IE 浏览器的兼容问题 */
    height: 0;
    overflow: hidden;
    /* visibility:hidden;为了去隐藏content中的内容 */
    visibility: hidden;
  }

  .left {
    float: left;
    height: 100%;
  }

  .right {
    height: 100%;
    position: relative;
    overflow-x: hidden;
    background: #eee;
  }

  .nav {
    height: 30px;
    width: 100%;
    background: #fff;
  }

  .main {
    position: absolute;
    top: 30px;
    left: 0;
    bottom: 30px;
    width: 100%;
   
  }
</style>