<template>
    <div class="navs">
        <div class="collapse-btn" @click="collapseChage">
            <i v-if="!isCollapse" class="el-icon-s-fold"></i>
            <i v-else class="el-icon-s-unfold"></i>
        </div>
        <ul>
            <li>
                {{ headerPaths }}
            </li>
            <li v-for="(item, index) in sidePaths" :key="index">
                <span>/</span>
                <router-link to="">{{item.charAt(0) == '/' ? item.substr(1) : item }}</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        data() {
            return {}
        },
        computed: {
            headerPaths() {
                return this.$store.state.headerPaths
            },
            sidePaths(){
                return this.$store.state.sidePaths
            },
            isCollapse() {
                return this.$store.state.isCollapse
            }
        },
        watch: {
            headerPaths() {
                return this.$store.state.headerPaths
            },
            sidePaths(){
                return this.$store.state.sidePaths
            },
            isCollapse() {
                return this.$store.state.isCollapse
            }
        },
        methods: {
            collapseChage() {
                let collapse = this.$store.state.isCollapse
                this.$store.commit('getCollapse', !collapse)
            }
        }
    }
</script>

<style scoped>
    .navs {
        height: 30px;
        line-height: 30px;
    }

    .navs ul {
        display: inline-block;
    }

    .navs ul li {
        display: inline-block;
    }

    .navs ul li a {
        /* margin: 0 5px; */
        color: #000;
    }

    .collapse-btn {
        display: inline-block;
        padding: 0 20px;
        cursor: pointer;
        height: 30px;
        line-height: 30px;
        font-size: 16px;
    }

    span{
        font-size: 20px;
        line-height: 30px;
        padding: 0 5px;
        margin-top: 5px;
    }
</style>