<template>
    <div class="header">
        <div class="logo">
            <img src="../../assets/img/logo03.png" height="80px">
            <span>视力档案后台管理系统</span>
            <!-- <span>学生健康管理系统</span> -->
        </div>

        <headerBar>
        </headerBar>

        <div class="name">
            <el-dropdown trigger="click" @command="handleCommand">
                <span class="el-dropdown-link">
                    {{userName}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command='editpwd'>个人中心</el-dropdown-item>
                    <el-dropdown-item command='logout'>退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <div class="img">
                <img src="../../assets/img/img.jpg" alt="" width="100%">
            </div>
        </div>
        <div class="mystyle">
            <el-dropdown trigger='click' @command="changeSize">
                <el-button icon='fa fa-text-height' :size='tabsize' type="primary">
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command='medium'>medium</el-dropdown-item>
                    <el-dropdown-item command='small'>small</el-dropdown-item>
                    <el-dropdown-item command='mini'>mini</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
    import headerBar from '../headerBar/index.vue'
    export default {
        components: {
            headerBar
        },
        data() {
            return {
                userName: '',
            }
        },
        computed: {
            tabsize() {
                return this.$store.state.tabsize
            }
        },
        mounted() {
            this.userName = JSON.parse(sessionStorage.getItem('userInfo')).username
        },
        methods: {
            // 退出登录
            handleCommand(command) {
                if (command === 'logout') {
                    this.$router.push('/login')
                    sessionStorage.clear()
                    this.$notify.success({
                        title: 'success',
                        message: '已退出登录',
                        showClose: false,
                        duration:2000,
                    });
                }
                if (command === 'editpwd') {
                    this.$router.push({
                        path: '/editpwd',
                        query: {
                            redirect: this.$router.currentRoute.fullPath
                        }
                    })

                }
            },
            // 更改表格尺寸
            changeSize(command) {
                localStorage.setItem('tabsize', command)
                this.$store.commit('getTabsize', command)
            }
        },
    }
</script>

<style scoped>
    .header {
        height: 80px;
        min-width: 1300px;
        width: 100%;
        background: #304156;
    }

    .logo {
        height: 80px;
        float: left;
        padding: 0 14px;
    }

    .logo img {
        float: left;
    }

    .logo span {
        float: left;
        font-size: 30px;
        color: #fff;
        line-height: 80px;
    }

    .toggle {
        position: absolute;
        right: 200px;
        top: 20px;
    }

    .name {
        height: 80px;
        float: right;
        margin-right: 50px;
    }

    .el-dropdown {
        height: 20px;
        margin-top: 30px;
        color: #fff;
    }

    .img {
        margin: 15px 20px 0 0;
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }

    .name>div {
        float: right;
    }

    .img img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    .mystyle {
        float: right;
        height: 80px;
        line-height: 40px;
        margin-right: 20px;
    }

    .mystyle .el-dropdown {
        margin-top: 20px !important;
        text-align: center;
    }

    .el-dropdown-menu {
        top: 52px !important;
    }
</style>