<template>
    <div class="topbar">
        <div class="items hidden">
            <el-menu v-show='dataList.length > 1' default-active="" class="el-menu-demo menuleft " mode="horizontal"
                @select="handleSelect" background-color="#304156" text-color="#fff" active-text-color="#1890ff">
                <el-menu-item v-for="(item, index) in dataList" :index="item.url" :key="index">
                    {{ item.cname }}
                </el-menu-item>
            </el-menu>
            <el-menu v-show="dataright.length > 0" default-active="" class="el-menu-demo-right" mode="horizontal"
                @select="handleSelect1" background-color="#304156" text-color="#fff" active-text-color="#1890ff">
                <el-menu-item index="0" v-for="(item, index) in dataright" :key="index">
                    {{ item.cname }}
                </el-menu-item>
            </el-menu>
            <div class="btns" v-if='dataList.length > 5'>
                <el-button class="btn_s" type="primary" icon="el-icon-more" @click='changeSize'></el-button>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                dataList: [],
                dataright: []
            };
        },
        methods: {
            handleSelect(index, key, keyPath) {
                let str = ''
                let sidebar = []
                if (this.dataList.length > 1) {
                    this.dataList.map((item) => {
                        if (item.url == key[0]) {
                            str = item.cname
                            sidebar = item.children
                        }
                    })
                }
                this.$store.commit('getHeaderPaths', str)
                this.$store.commit('getSidedata', sidebar)
                var classList = document.getElementsByClassName('items')[0].classList
                classList.remove('show')
                classList.add('hidden')
                let keys = ''
                for (let i = 0; i < this.dataList.length; i++) {
                    if (key[0] == this.dataList[i].url) {
                        keys = i
                    }
                }
                if (keys > 4) {
                    let obj = this.dataList[keys]
                    this.dataright.splice(0, 1)
                    this.dataright.push(obj)
                } else {
                    if (this.dataright.length > 0) {
                        this.dataright.splice(0, 1)
                    }
                }
            },
            changeSize() {
                var classList = document.getElementsByClassName('items')[0].classList
                classList.toggle('hidden')
                classList.toggle('show')
                document.addEventListener('click', function (e) {
                    if (e.target.className != 'el-icon-more' && e.target.className != 'btns' && e.target
                        .className != 'btn_s' && e.target.className != 'menuleft') {
                        document.getElementsByClassName('items')[0].classList.remove('show')
                        document.getElementsByClassName('items')[0].classList.add('hidden')
                    }
                })
            },
            handleSelect1(index, key, keyPath) {

            }
        },
        created() {
            let menus = JSON.parse(localStorage.getItem('menus'))
            console.log("获取到的arr",menus)
            if(menus.length > 0){
                this.dataList = menus
                this.$store.commit('getAlldata', menus)
                this.$store.commit('getHeaderPaths', menus[0].cname)
                this.$store.commit('getSidedata', menus[0].children)
            }else{
                this.$store.commit('getAlldata', [])
                this.$store.commit('getHeaderPaths', '')
                this.$store.commit('getSidedata', [])
            }
            this.$api.menu.updateList().then(res => {
                console.log("获取到的menu=>",res)
                let datas = res.obj
                localStorage.setItem('menuArr', JSON.stringify(datas))
                this.tableData = this.$table2Tree(datas)
                this.loading = false
            }).catch(error => {
                console.log(error);
            })
        },
        computed: {
            mymenu() {
                return this.$store.state.myMenu
            },
        },
        mounted() {
            let menuItems = document.getElementsByClassName('el-menu-item')
            menuItems.forEach(element => {
                if (element.innerText == this.$store.state.headerPaths) {
                    element.click()
                    // this.handleSelect
                }
            });
        },
    }
</script>

<style scoped>
    .el-menu--horizontal>.el-menu-item.is-active {
        border-bottom-width: 5px;
    }

    .el-menu.el-menu--horizontal {
        border-bottom: none !important;
    }

    .topbar {
        height: 80px;
        float: left;
    }


    .items {
        position: absolute;
        width: 700px;
        height: 80px;
        background: #304156;
        z-index: 999;
    }

    .items::after {
        content: "";
        display: block;
        clear: both;
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }

    .is-active {
        background: rgb(38, 52, 69) !important;
        border-bottom-width: 5px;
    }

    .menuleft {
        width: 550px;
        min-height: 320px;
        float: left;
        padding-left: 25px;
    }

    .el-menu-item {
        height: 80px !important;
        line-height: 80px !important;
    }

    .el-menu .el-menu-item {
        width: 100px;
        padding: 0;
        text-align: center;
    }

    .btns {
        float: left;
        width: 50px;
        height: 80px;
        padding-top: 20px;
    }

    .el-menu-demo-right {
        float: left;
        width: 100px;
        height: 80px;
    }

    .btns button {
        width: 50px;
        text-align: center;
        height: 40px;
        padding: 0 !important;
        margin: 0 !important;
        background: rgba(0, 0, 0, 0);
        border: none;
    }

    .hidden {
        float: left;
        overflow: hidden;
        position: relative;
    }

    .show {
        float: left;
        position: relative;
    }
</style>