<template>
    <div class="mains">
            <router-view></router-view>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
 .mains{
     width: 100%;
     height: 100%;
     /* padding: 1%; */
 }

</style>