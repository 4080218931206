<template>
    <div class="box">
        <el-menu default-active="path" class="el-menu-vertical-demo" background-color="#304156" text-color="#bfcbd9"
            active-text-color="#1890ff" @open="handleOpen" :collapse="collapse" @select="select" @close="handleClose"
            unique-opened router>
            <div v-for="(item1, index) in list" :key="index">
                <!-- 第一级菜单 -->
                <div v-if="item1.children.length>0">
                    <el-submenu v-if="item1.mtype == 1" :index="item1.cname">
                        <template slot="title">
                            <i :class='item1.icon'></i>
                            <span>{{ item1.cname }}</span>
                        </template>
                        <!-- 第二级菜单 -->
                        <div v-for="(item2, index) in item1.children" :key="index">
                            <el-menu-item :index="item2.url" v-if="item2.mtype == 2">
                                {{ item2.cname }}
                            </el-menu-item>
                        </div>
                    </el-submenu>
                </div>

                <div v-if="item1.children.length==0">
                    <el-menu-item :index="item1.url" v-if="item1.mtype == 2">
                        <i :class='item1.icon'></i>
                        <span slot='title'>{{ item1.cname }}</span>
                    </el-menu-item>
                </div> 
            </div>

        </el-menu>
    </div>
</template>

<script>
    export default {
        data() {
            return {}
        },
        computed: {
            list() {
                return this.$store.state.sideData
            },
            collapse() {
                return this.$store.state.isCollapse
            },
            // paths() {
            //     return this.$store.state.sidePaths
            // },
            // path() {
            //     return this.$route.path
            // }
        },
        watch: {
            list() {
                return this.$store.state.sideData
            },
            collapse() {
                return this.$store.state.isCollapse
            },
            // paths() {
            //     return this.$store.state.paths
            // }
        },
        methods: {
            handleOpen(key, keyPath) {
                this.$store.commit('getSidePaths', keyPath)
            },
            handleClose(key, keyPath) {
                this.$store.commit('getSidePaths', keyPath)
            },
            select(index, indexPath) {

                if (indexPath.length > 1) {
                    for (let i = 0; i < this.list.length; i++) {
                        if (this.list[i].children) {
                            for (let j = 0; j < this.list[i].children.length; j++) {
                                if (this.list[i].children[j].url == index) {
                                    indexPath[1] = this.list[i].children[j].cname
                                }
                            }
                        }
                    }
                } else {
                    JSON.parse(localStorage.getItem('menuArr'))?.map((item) => {
                        if (item.url == index) {
                            indexPath[0] = item.cname
                        }
                    })
                }
                this.$store.commit('getSidePaths', indexPath)
            },

        },
        mounted() {},
    };
</script>

<style scoped>
    .box {
        width: 100%;
        height: 100%;
    }

    .el-menu-item,
    .el-submenu/deep/.el-submenu__title {
        height: 45px !important;
        line-height: 45px !important;
    }



    .el-menu {
        height: 100%;
        overflow-y: scroll;
    }

    .logo {
        display: inline-block;
        margin-right: 10px;
    }

    .el-menu-vertical-demo::-webkit-scrollbar {
        width: 0;
    }

    .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 200px;
        overflow-x: hidden;
    }

    .el-menu-item [class^=fa] {
        margin-right: 5px;
        width: 24px;
        text-align: center;
        font-size: 18px;
        vertical-align: middle;
    }

    .is-active {
        background: rgb(38, 52, 69) !important;
    }
</style>